/*
Version: 2.1.1
File Description: Light Blue Skin
*/
@import "../mixins";

//== Colors
//
//## Gray and brand colors for use across The Project Template.
$gray-dark:             #333333;
$gray:                  #777777;
$gray-light:            #999999;
$gray-lighter:          #cdcdcd;
$white:                 #ffffff;
$black:                 #000000;

//** Base Colors of Light Blue Skin.
$base-color:            #09afdf;
$base-color-opacity-50: rgba($base-color, 0.5);
$base-color-opacity-60: rgba($base-color, 0.6);
$base-color-opacity-70: rgba($base-color, 0.7);
$base-color-opacity-80: rgba($base-color, 0.8);
$base-color-hover:      #0c9ec7;
//** Dark Header Top and Header
$dark-header:                   #394245;
$dark-header-opacity-95:        rgba($dark-header, 0.95);
$dark-header-opacity-90:        rgba($dark-header, 0.9);
$dark-header-opacity-80:        rgba($dark-header, 0.8);
$dark-header-opacity-60:        rgba($dark-header, 0.6);
$dark-header-top:               #2e3537;
$dark-header-top-border:        #252a2c;
$dark-header-top-opacity-60:    rgba($dark-header-top-border, 0.5);
$dark-navigation-item-border:   rgba($dark-header, 0.8);

/* 1- Typography
-------------------------------------------------
-------------------------------------------------*/
a {
  color: $base-color;
}
a:hover {
  color: $base-color-hover;
}
a:focus,
a:active {
  color: $base-color-hover;
}
mark,
.mark {
  background-color: $base-color;
  color: $white;
}
.text-default {
  color: $base-color;
}

/* Tables
---------------------------------- */
.table.table-colored {
  border-bottom-color: $base-color;
}
.table-colored > thead > tr > th {
  border-color: $base-color;
  background-color: $base-color;
  color: $white;
}

/* 2- Layout
-------------------------------------------------
-------------------------------------------------*/
.header-top.dark {
  background-color: $dark-header-top;
  border-bottom: 1px solid $dark-header-top-border;
}
.header.dark {
  background-color: $dark-header;
  border-top-color: $dark-header;
}
.header-top.colored {
  background-color: $base-color;
  border-bottom: 1px solid $base-color;
}
.default-bg {
  background-color:$base-color;
  color: $white;
}
.default-bg-50 {
  background-color:$base-color-opacity-50;
  color: $white;
}
/*Transparent Header*/
.transparent-header  header.header.dark.transparent-header-on {
  background-color: $dark-header-opacity-80;
  border-top-color: $dark-header-top-opacity-60;
}
/*Fixed Navigation*/
.fixed-header-on .dark.header.fixed {
  background-color: $dark-header-opacity-95;
}
/* Small devices (tablets, 768px and up) */
@media (min-width:992px) {
  .fixed-header-on .dark.header .dropdown-menu {
    border: 1px solid $dark-header-top;
  }
}
/*Translucent Backgrounds*/
.default-translucent-bg:after {
  background-color: $base-color-opacity-70;
}
.default-translucent-bg.hovered:hover:after {
  background-color: $base-color-opacity-80;
}
.default-hovered:hover:after,
.default-hovered:hover {
  background-color: $base-color;
}

/* 4 Pages
-------------------------------------------------
-------------------------------------------------*/
/* Blog Timeline layout
---------------------------------- */
.timeline-date-label:after {
  background-color: $base-color;
}

/* 5 Components
-------------------------------------------------
-------------------------------------------------*/
/* Buttons
---------------------------------- */
.btn-default {
  background-color: $base-color;
  border-color: $base-color-hover;
}
.btn-default:hover,
.btn-default:focus,
.btn-default.focus,
.btn-default:active,
.btn-default.active,
.show > .dropdown-toggle.btn-default {
  background-color: $base-color-hover;
  border-color: $base-color-hover;
}
.btn-default.disabled,
.btn-default[disabled],
fieldset[disabled] .btn-default {
  background-color: $base-color;
  border-color: $base-color-hover;
}
.btn-default-transparent {
  border-color: $base-color;
}
.btn-default-transparent:hover,
.btn-default-transparent:focus,
.btn-default-transparent.focus,
.btn-default-transparent:active,
.btn-default-transparent.active,
.show > .dropdown-toggle.btn-default-transparent {
  background-color: $base-color-hover;
  border-color: $base-color-hover;
}
.default-bg .btn-default,
.default-translucent-bg .btn-default,
.default-bg .btn-default-transparent,
.default-translucent-bg .btn-default-transparent {
  color: $base-color;
}
.btn-white:hover,
.btn-white:focus,
.btn-white.focus,
.btn-white:active,
.btn-white.active,
.show > .dropdown-toggle.btn-white {
  color: $base-color-hover;
}
.btn-animation:hover:before,
.btn-animation:focus:before {
  background: $base-color-hover;
}

/* Pager
---------------------------------- */
ul.pagination .page-item .page-link:hover,
ul.pagination .page-item .page-link:focus {
  background-color: $base-color-hover;
  border-color: $base-color-hover;
}
ul.pagination .page-item.active .page-link,
.page-item.active .page-link:hover,
.page-item.active .page-link:focus {
  background-color: $base-color;
  border-color: $base-color;
}

/* Breadcrumb
---------------------------------- */
.banner .breadcrumb .breadcrumb-item a:hover {
  color: $base-color-hover;
}
.banner {
  .dark.breadcrumb-container {
    background-color: rgba($dark-header, 0.60);
  }
}

/* Nav pills
---------------------------------- */
.nav-pills.style-2 {
  > li .nav-link.active,
  > li .nav-link.active:hover,
  > li .nav-link.active:focus,
  > li .nav-link:hover,
  > li .nav-link:focus {
    color: $base-color;
  }
}
.nav.flex-column {
  & > li .nav-link.active,
  & > li .nav-link.active:hover,
  & > li .nav-link.active:focus,
  & > li .nav-link:hover,
  & > li .nav-link:focus {
    color: $base-color;
  }
}
.nav-pills > li .nav-link.active:after {
  color: $base-color;
}
.nav.flex-column {
  &:not(.list-style-icons) > li .nav-link.active:hover:after,
  &:not(.list-style-icons) > li .nav-link.active:focus:after,
  &:not(.list-style-icons) > li .nav-link:hover:after,
  &:not(.list-style-icons) > li .nav-link:focus:after {
    color: $base-color-hover;
  }
}
.nav.flex-column {
  &.list-style-icons > li .nav-link.active > i,
  &.list-style-icons > li .nav-link:hover > i,
  &.list-style-icons > li .nav-link:focus > i {
    color: $base-color-hover;
  }
}
.dark .footer-content .nav.flex-column {
  & > li > a.active:hover,
  & > li > a:hover {
    color: $base-color-hover;
  }
}

/* Collapse
---------------------------------- */
.collapse-style-2 {
  .card-header a {
    background-color: $base-color;
  }
}

/* Progress bars
---------------------------------- */
.progress-bar-default {
  background-color: $base-color;
}

/* Forms
---------------------------------- */
.default-bg {
  .form-control {
    border-color: $base-color-hover;
  }
  .input-group-addon {
    border-color: $base-color-hover;
  }
}

/*Icons
---------------------------------- */
.icon {
  &.default-bg {
    background-color: $base-color;
  }
  &.light-bg {
    color: $base-color;
  }
}

/*Listing Items
---------------------------------- */
.listing-item {
  .overlay-container .badge {
    border: 1px solid $base-color;
    color: $base-color;
  }
}

/*Modals
---------------------------------- */
.modal-content .modal-header {
  background-color: $base-color;
}

/*Pace (Page loader)
---------------------------------- */
/*loader 1*/
.page-loader-1 {
  .pace {
    border: 1px solid $base-color;
  }
  .pace .pace-progress {
    background: $base-color;
  }
}
.page-loader-2 {
  .pace .pace-progress:before {
    background: $base-color;
  }
  .pace .pace-activity {
    border: 5px solid $base-color;
  }
  .pace .pace-activity:after {
    border: 5px solid $base-color;
  }
  .pace .pace-activity:before {
    border: 5px solid $base-color;
  }
}
.page-loader-3 {
  .pace .pace-progress {
    background: $base-color;
  }
}
.page-loader-4 {
  .pace .pace-progress {
    background: $base-color;
  }
}
.page-loader-5 {
  .pace .pace-progress:after {
    color: $base-color;
  }
}
.page-loader-6 {
  .pace .pace-activity {
    background: $base-color;
  }
}

/* 6 Navigations
-------------------------------------------------
-------------------------------------------------*/
/* 6.1 Light Version
----------------------------------------------------------------------------- */
.header .navbar-nav li .nav-link:focus,
.header .navbar-nav li .nav-link:hover,
.header .navbar-nav .show > .nav-link,
.header .navbar-nav li .nav-link.show,
.header .navbar-nav .active.show > .nav-link,
.header .navbar-nav li.show .nav-link.active {
  color: $base-color;
}
.header .navbar-nav .active > .nav-link,
.header .navbar-nav li .nav-link.active {
  color: $base-color;
}
.header .navbar-nav li li a:focus,
.header .navbar-nav li li a:hover,
.header .navbar-nav li .show > a,
.header .navbar-nav li li a.show,
.header .navbar-nav li .active > a,
.header .navbar-nav li li a.active {
  color: $base-color-hover;
}
header:not(.dark) .mega-menu .menu > li > a:hover i,
header:not(.dark) .dropdown-menu > li > a:hover i,
header:not(.dark) .mega-menu .menu > li.active > a i,
header:not(.dark) .dropdown-menu > li.active > a i {
  color: $base-color;
}
.navbar-light .navbar-toggler:hover,
.navbar-light .navbar-toggler:focus {
  border-color: $base-color-hover;
}

/* Small devices (tablets, phones less than 767px) */
@media (max-width: 991px) {
  .header .navbar-nav .active .nav-link,
  .header .navbar-nav .active .nav-link:hover,
  .header .navbar-nav .active .nav-link:focus {
    color: $base-color-hover;
  }

  /* first level item hover and focus states */
  .header .navbar-nav li > a:hover,
  .header .navbar-nav li > a:focus,
  .header .navbar-nav .active > a,
  .header .navbar-nav .active > a:hover,
  .header .navbar-nav .active > a:focus {
    color: $base-color-hover;
  }

  /* second level item on hover/focus */
  .header .navbar-nav .show .dropdown-menu > li > a:hover,
  .header .navbar-nav .show .dropdown-menu > li > a:focus {
    color: $base-color-hover;
  }

  /* Arrow for parent menu item */
  .header .navbar-nav .dropdown.show > .dropdown-toggle::after {
    color: $base-color;
  }
}

/* 6.2 Dark Version
----------------------------------------------------------------------------- */
/* first level active menu item when opened */
.dark.header .navbar-nav .show .nav-link,
.dark.header .navbar-nav .show .nav-link:hover,
.dark.header .navbar-nav .show .nav-link:focus,
.dark.header .navbar-nav .dropdown .nav-link:focus {
  border-bottom-color: $dark-header-top;
}

/* second level menu item */
.dark.header .dropdown-menu {
  background-color: $dark-header-top;
}
.dark.header .dropdown-menu .divider {
  background-color: rgba($dark-header, 0.80);
}
.dark.header .dropdown-menu > li > a,
.dark.header .navbar-nav .mega-menu .menu > li > a {
  border-bottom: 1px solid rgba($dark-header, 0.80);
}
.dark.header .mega-menu .menu > li > a:hover,
.dark.header .mega-menu .menu > li > a:focus {
  border-color: rgba($dark-header, 0.80);
}
.dark.header .dropdown-menu > li > a:hover,
.dark.header .dropdown-menu > li > a:focus,
.dark.header .navbar-nav .show > a,
.dark.header .navbar-nav .show > a:hover,
.dark.header .navbar-nav .show > a:focus,
.dark.header .dropdown-menu > .active > a,
.dark.header .dropdown-menu > .active > a:hover,
.dark.header .dropdown-menu > .active > a:focus,
.dark.header .dropdown-menu .menu > .active > a,
.dark.header .dropdown-menu .menu > .active > a:hover,
.dark.header .dropdown-menu .menu > .active > a:focus {
  border-color: rgba($dark-header, 0.80);
}

/* 6.3 Navigation style 2
----------------------------------------------------------------------------- */
@media (min-width: 992px) {
  .header.white-bg .main-navigation--style-2 .navbar-nav li .nav-link:focus,
  .header.white-bg .main-navigation--style-2 .navbar-nav li .nav-link:hover,
  .header.white-bg .main-navigation--style-2 .navbar-nav li.show .nav-link {
    color: $base-color;
  } 
}

/* 7 Blocks/Widgets
-------------------------------------------------
-------------------------------------------------*/
/* Social icons block
---------------------------------- */
.social-links {
  li a:hover {
    border-color: $base-color-hover;
    color: $base-color-hover;
  }
  &.animated-effect-1 li a:after {
    background-color: $base-color;
  }
  &.default li a {
    background-color: $base-color;
    border: 1px solid $base-color-hover;
  }
}
.header-top:not(.dark) .social-links .dropdown.show > button > i:before {
  color: $base-color;
}

/*Header Dropdowns (search, cart etc)
---------------------------------- */
.header-dropdown-buttons .btn-group > .btn:hover {
  background-color: $base-color;
  border-color: $base-color-hover;
}
.header-dropdown-buttons .btn-group.show > .btn {
  background-color: $base-color;
  border-color: $base-color-hover;
}
.dark.header-top .dropdown-menu {
  border: 1px solid $dark-header-top-border;
  background: $dark-header-top;
}
.colored.header-top .dropdown-menu {
  border: 1px solid $base-color;
  background: $base-color;
}

/* Media
---------------------------------- */
.media:hover .icon {
  color: $base-color;
  border: 1px solid $base-color;
}

/* Full Width Content
---------------------------------- */
/* Large and Medium devices (desktops, tablets less than 1199px) */
@media (max-width: 1199px) {
  .full-width-section:not(.no-image) .full-text-container.default-bg {
    background-color: rgba($base-color, 0.6);
  }
}

/* Pricing tables
---------------------------------- */
.popover-title {
  background-color: $base-color;
}

/* Tags cloud block
---------------------------------- */
.tag {
  a {
    background-color: $base-color;
    border: 1px solid $base-color-hover;
  }
  a:hover {
    color: $base-color;
    border-color: $base-color-hover;
  }
}

/* 8 Main Slideshow
-------------------------------------------------
-------------------------------------------------*/
.tp-bannertimer {
  background-color: rgba($base-color, 0.8);
}

/* 9 Slick Carousel
-------------------------------------------------
-------------------------------------------------*/
.content-slider-with-large-controls .slick-prev:after,
.content-slider-with-large-controls-autoplay .slick-prev:after {
  color: $base-color;
}
.content-slider-with-large-controls .slick-next:after,
.content-slider-with-large-controls-autoplay .slick-next:after {
  color: $base-color;
}

/* 10 Full Page
-------------------------------------------------
-------------------------------------------------*/
#fp-nav ul li a span,
.fp-slidesNav ul li a span {
  background: rgba($base-color, 0.8);
}
