/* 7 Blocks/Widgets
----------------------------------------------------------------------------- */
/* Logo, Site Slogan
---------------------------------- */
#logo img:hover,
#logo-mobile img:hover {
  opacity: 0.8;
  filter: alpha(opacity=80);
}
.header.centered #logo img {
  margin: 0 auto;
}
#logo-footer {
  margin-bottom: 20px;
}
.site-slogan {
  color: $gray;
  font-size: 11px;
  padding: 3px 0 0;
  font-weight: 300;
  @include opacity(1);
  @include transition (all 0.3s ease-in-out);
}
.offcanvas {
  .logo {
    margin: 20px 0 10px;
  }
  .logo img {
    margin: 0 auto;
  }
  .site-slogan {
    text-align: center;
    margin-bottom: 20px;
    margin-top: -10px;
  }
}
.transparent-header .header:not(.dark) .site-slogan {
  color: $black;
}
.header.dark .site-slogan {
  color: $gray-lighter;
  text-shadow: 1px 1px rgba($black, 0.4);
}
.transparent-header .header.dark .site-slogan {
  color: #f1f1f1;
}

/* Medium devices (tablets, phones) */
@media (min-width: 992px) and (max-width: 1199px) {
  .site-slogan {
    font-size: 11px;
  }
}

/* Social icons block
---------------------------------- */
.social-links {
  list-style: none;
  font-size: 0;
  margin-top: 20px;
  margin-bottom: 20px;
  // RTL support.
  // The following mixin depends on the $text-direction variable in style.scss file OR in rtl_style.scss file.
  // RTL related mixins are located in mixins/_rtl.scss file.
  @include padding-left (0);
  li {
    display: inline-block;
    // RTL support.
    // The following mixin depends on the $text-direction variable in style.scss file OR in rtl_style.scss file.
    // RTL related mixins are located in mixins/_rtl.scss file.
    @include margin (0, 2px, 2px, 0);
  }

  li a {
    width: 40px;
    height: 40px;
    display: block;
    text-align: center;
    font-size: 18px;
    border: 1px solid #e7e7e7;
    color: $gray-lighter;
    @include border-radius(4px);
  }
  li a i {
    line-height: 40px;
  }
  li a:hover {
    background-color: transparent;
  }
  &.animated-effect-1 li a {
    @include transition (color 0.4s);
    position: relative;
    z-index: 1;
  }
  &.animated-effect-1 li a:hover {
    color: $white!important;
    @include transition (color 0.3s);
  }
  &.animated-effect-1 li a:after {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    @include border-radius(50%);
    content: '';
    top: 0px;
    left: 0px;
    padding: 0px;
    z-index: -1;
    -webkit-transition: -webkit-transform 0.4s, opacity 0.4s, background-color 0.4s;
    -moz-transition: -moz-transform 0.4s, opacity 0.4s, background-color 0.4s;
    transition: transform 0.4s, opacity 0.4s, background-color 0.4s;
    @include scale(1.4);
    @include opacity(0);
  }
  &.animated-effect-1 li a:hover:after {
    @include scale(1);
    @include opacity(1);
    -webkit-transition: -webkit-transform 0s, opacity 0.3s, background-color 0.3s;
    -moz-transition: -moz-transform 0s, opacity 0.3s, background-color 0.3s;
    transition: transform 0s, opacity 0.3s, background-color 0.3s;
  }
  &.animated-effect-1:not(.circle) li a:after {
    @include border-radius(0%);
  }
  &.default li a {
    color: $white;
  }
  &.default li a:hover {
    background-color: $white;
    color: $gray;
  }
}
.dark .social-links li a {
  background-color: rgba($black, 0.2);
  border: 1px solid rgba($black, 0.1);
  color: $white;
}
.dark.social-links li a {
  background-color: rgba($black, 0.6);
  border: 1px solid rgba($black, 0.1);
  color: $white;
}
.dark.social-links li a:hover {
  background-color: rgba($black, 0.8);
  color: $white;
  border-color: rgba($black, 0.8);
}
.colored .social-links li a {
  background-color: rgba($black, 0.2);
  border: 1px solid rgba($black, 0.01);
  color: $white;
}
.header-top .social-links {
  margin: 0;
  display: inline-block;
  margin-top: 2px;
}
.footer-content .social-links li {
  // RTL support.
  // The following mixin depends on the $text-direction variable in style.scss file OR in rtl_style.scss file.
  // RTL related mixins are located in mixins/_rtl.scss file.
  @include margin (0, 5px, 5px, 0);
}
.footer-content .social-links li a {
  color: $gray;
  background-color: transparent;
  border-color: $gray;
}
.footer-content .social-links li a:hover {
  color: $white;
  background-color: $gray;
  border-color: $gray;
}
.text-white .social-links li a {
  color: $white;
  border-color: $white;
}
.text-white .social-links li a:hover,
.text-white .social-links li a:focus {
  color: $gray-dark;
  background-color: $white;
  border-color: $white;
}
.social-links.small li a {
  width: 30px;
  height: 30px;
  font-size: 14px;
}
.social-links.small li a i {
  line-height: 29px;
}
.social-links.large li a {
  width: 60px;
  height: 60px;
  font-size: 24px;
}
.social-links.large li a i {
  line-height: 60px;
}
.social-links.square li a,
.square {
  @include border-radius(0);
}
.social-links.circle li a {
  @include border-radius(100%);
}
.social-links.animated-effect-1 li.twitter a:hover {
  border-color: $twitter;
}
.social-links:not(.animated-effect-1) li.twitter a:hover,
.colored.social-links li.twitter a,
.social-links.animated-effect-1 li.twitter a:after,
.btn.twitter {
  background-color: $twitter;
  border-color: $twitter;
  color: $white;
}
.social-links.animated-effect-1 li.skype a:hover {
  border-color: $skype;
}
.social-links:not(.animated-effect-1) li.skype a:hover,
.colored.social-links li.skype a,
.social-links.animated-effect-1 li.skype a:after,
.btn.skype {
  background-color: $skype;
  border-color: $skype;
  color: $white;
}
.social-links.animated-effect-1 li.linkedin a:hover {
  border-color: $linkedin;
}
.social-links:not(.animated-effect-1) li.linkedin a:hover,
.colored.social-links li.linkedin a,
.social-links.animated-effect-1 li.linkedin a:after,
.btn.linkedin {
  background-color: $linkedin;
  border-color: $linkedin;
  color: $white;
}
.social-links.animated-effect-1 li.googleplus a:hover {
  border-color: $googleplus;
}
.social-links:not(.animated-effect-1) li.googleplus a:hover,
.colored.social-links li.googleplus a,
.social-links.animated-effect-1 li.googleplus a:after,
.btn.googleplus {
  background-color: $googleplus;
  border-color: $googleplus;
  color: $white;
}
.social-links.animated-effect-1 li.youtube a:hover {
  border-color: $youtube;
}
.social-links:not(.animated-effect-1) li.youtube a:hover,
.colored.social-links li.youtube a,
.social-links.animated-effect-1 li.youtube a:after,
.btn.youtube {
  background-color: $youtube;
  border-color: $youtube;
  color: $white;
}
.social-links.animated-effect-1 li.flickr a:hover {
  border-color: $flickr;
}
.social-links:not(.animated-effect-1) li.flickr a:hover,
.colored.social-links li.flickr a,
.social-links.animated-effect-1 li.flickr a:after,
.btn.flickr {
  background-color: $flickr;
  border-color: $flickr;
  color: $white;
}
.social-links.animated-effect-1 li.facebook a:hover {
  border-color: $facebook;
}
.social-links:not(.animated-effect-1) li.facebook a:hover,
.colored.social-links li.facebook a,
.social-links.animated-effect-1 li.facebook a:after,
.btn.facebook {
  background-color: $facebook;
  border-color: $facebook;
  color: $white;
}
.social-links.animated-effect-1 li.pinterest a:hover {
  border-color: $pinterest;
}
.social-links:not(.animated-effect-1) li.pinterest a:hover,
.colored.social-links li.pinterest a,
.social-links.animated-effect-1 li.pinterest a:after,
.btn.pinterest {
  background-color: $pinterest;
  border-color: $pinterest;
  color: $white;
}
.social-links.animated-effect-1 li.instagram a:hover {
  border-color: $instagram;
}
.social-links:not(.animated-effect-1) li.instagram a:hover,
.colored.social-links li.instagram a,
.social-links.animated-effect-1 li.instagram a:after,
.btn.instagram {
  background-color: $instagram;
  border-color: $instagram;
  color: $white;
}
.social-links.animated-effect-1 li.vimeo a:hover {
  border-color: $vimeo;
}
.social-links:not(.animated-effect-1) li.vimeo a:hover,
.colored.social-links li.vimeo a,
.social-links.animated-effect-1 li.vimeo a:after,
.btn.vimeo {
  background-color: $vimeo;
  border-color: $vimeo;
  color: $white;
}
.social-links.animated-effect-1 li.tumblr a:hover {
  border-color: $tumblr;
}
.social-links:not(.animated-effect-1) li.tumblr a:hover,
.colored.social-links li.tumblr a,
.social-links.animated-effect-1 li.tumblr a:after,
.btn.tumblr {
  background-color: $tumblr;
  border-color: $tumblr;
  color: $white;
}
.social-links.animated-effect-1 li.soundcloud a:hover {
  border-color: $soundcloud;
}
.social-links:not(.animated-effect-1) li.soundcloud a:hover,
.colored.social-links li.soundcloud a,
.social-links.animated-effect-1 li.soundcloud a:after,
.btn.soundcloud {
  background-color: $soundcloud;
  border-color: $soundcloud;
  color: $white;
}
.social-links.animated-effect-1 li.foursquare a:hover {
  border-color: $foursquare;
}
.social-links:not(.animated-effect-1) li.foursquare a:hover,
.colored.social-links li.foursquare a,
.social-links.animated-effect-1 li.foursquare a:after,
.btn.foursquare {
  background-color: $foursquare;
  border-color: $foursquare;
  color: $white;
}
.social-links.animated-effect-1 li.dribbble a:hover {
  border-color: $dribbble;
}
.social-links:not(.animated-effect-1) li.dribbble a:hover,
.colored.social-links li.dribbble a,
.social-links.animated-effect-1 li.dribbble a:after,
.btn.dribbble {
  background-color: $dribbble;
  border-color: $dribbble;
  color: $white;
}
.social-links.animated-effect-1 li.xing a:hover {
  border-color: $xing;
}
.social-links:not(.animated-effect-1) li.xing a:hover,
.colored.social-links li.xing a,
.social-links.animated-effect-1 li.xing a:after,
.btn.xing {
  background-color: $xing;
  border-color: $xing;
  color: $white;
}
.social-links.animated-effect-1 li.behance a:hover {
  border-color: $xing;
}
.social-links:not(.animated-effect-1) li.behance a:hover,
.colored.social-links li.behance a,
.social-links.animated-effect-1 li.behance a:after,
.btn.behance {
  background-color: $behance;
  border-color: $behance;
  color: $white;
}
.social-links.animated-effect-1 li.vine a:hover {
  border-color: $xing;
}
.social-links:not(.animated-effect-1) li.vine a:hover,
.colored.social-links li.vine a,
.social-links.animated-effect-1 li.vine a:after,
.btn.vine {
  background-color: $vine;
  border-color: $vine;
  color: $white;
}
.social-links.animated-effect-1 li.stumbleupon a:hover {
  border-color: $xing;
}
.social-links:not(.animated-effect-1) li.stumbleupon a:hover,
.colored.social-links li.stumbleupon a,
.social-links.animated-effect-1 li.stumbleupon a:after,
.btn.stumbleupon {
  background-color: $stumbleupon;
  border-color: $stumbleupon;
  color: $white;
}
.social-links .dropdown > button {
  padding: 1px 15px 0px;
  font-size: 16px;
  color: $gray-light;
  text-align: center;
  min-width: 0;
  margin: 0;
  background-color: transparent;
  @include box-shadow(none !important);
}
.social-links .dropdown > button i {
  width: 15px;
}
.header-top.colored .social-links .dropdown > button > i:before,
.header-top.colored .social-links .dropdown.show > button > i:before {
  color: $white;
}
.header-top .social-links .dropdown.show > button > i:before {
  content: "\f00d";
}
.social-links .dropdown-menu {
  z-index: 103;
  padding: 5px;
  min-width: 270px;
  margin-top: 6px;
  font-size: 0;
  @include box-shadow(none);
  background-color: #f2f2f2;
  border: 1px solid #D0D0D0;
  border-top: none;
}

// RTL support
// $text-direction variable is setted to "ltr" inside style.scss file and to "rtl" inside rtl_style.scss file.
@if $text-direction == rtl {
  /* Small devices (tablets, phones less than 767px) */
  @media (max-width: 767px) {
    .social-links .dropdown-menu {
      right: 0;
      left: auto;
    }
  }
}
.social-links .dropdown-menu li a,
.dark .social-links .dropdown-menu li a i,
.colored .social-links .dropdown-menu li a i {
  padding: 0;
  line-height: 30px;
  color: $white;
}
.social-links .dropdown-menu li a i {
  padding-left: 0;
}
.social-links .dropdown-menu li a:hover i {
  color: $white;
}

/*Header Dropdowns (search, cart etc)
---------------------------------- */
/*Header Dropdowns*/
.header-dropdown-buttons {
  padding-top: 20px;
}
.transparent-header .header-dropdown-buttons .btn-group > .btn {
  background-color: transparent;
  border: 1px solid $gray-light;
  color: $black;
}
.transparent-header.gradient-background-header .header-dropdown-buttons .btn-group > .btn {
  border: 1px solid $gray-dark;
}
.header-dropdown-buttons .btn-group:first-child {
  // RTL support.
  // The following mixin depends on the $text-direction variable in style.scss file OR in rtl_style.scss file.
  // RTL related mixins are located in mixins/_rtl.scss file.
  @include margin-right (5px);
}
.header-dropdown-buttons .btn-group > .btn {
  @include border-radius(100%);
  width: 40px;
  height: 40px;
  padding: 0;
  line-height: 38px;
  text-align: center;
  background-color: #f2f2f2;
  border: 1px solid #e9e9e9;
  @include transition (all 0.2s ease-in-out);
  margin: 0;
}
.header-dropdown-buttons .btn-group > .btn:hover {
  color: $white;
}
.header-dropdown-buttons .btn-group .dropdown-menu {
  z-index: 103;
  padding: 20px;
  min-width: 280px;
  margin-top: 20px;
  top: 100%;
  border: none;
  // RTL support.
  // The following mixin depends on the $text-direction variable in style.scss file OR in rtl_style.scss file.
  // RTL related mixins are located in mixins/_rtl.scss file.
  @include border-radius(0 0 5px 5px);
  // RTL support
  // $text-direction variable is setted to "ltr" inside style.scss file and to "rtl" inside rtl_style.scss file.
  @if $text-direction == rtl {
    right: auto;
    left: 0;
  }
}
.dark .header-dropdown-buttons .btn-group .dropdown-menu {
  margin-top: 19px;
}
.dark .header-dropdown-buttons .cart {
  color: #cacaca;
}
.header-dropdown-buttons .btn-group.show > .btn {
  color: $white;
}
.header.centered .header-dropdown-buttons .btn-group .dropdown-menu {
  margin-top: 8px;
}
.dark .header-dropdown-buttons .btn-group > .btn,
.colored .header-dropdown-buttons .btn-group > .btn {
  background-color: rgba($black, 0.2);
  border: 1px solid rgba($black, 0.15);
  text-shadow: 1px 1px rgba($black, 0.7);
  color: $gray-lighter;
}
.dark .header-dropdown-buttons .btn-group > .btn:hover,
.colored .header-dropdown-buttons .btn-group > .btn:hover {
  background-color: rgba($black, 0.3);
  border: 1px solid rgba($black, 0.25);
}
.dark .header-dropdown-buttons .btn-group.show > .btn,
.colored .header-dropdown-buttons .btn-group.show > .btn {
  background-color: rgba($black, 0.3);
  border: 1px solid rgba($black, 0.25);
}
.btn-group.show .dropdown-animation {
  @include animation-duration(0.3s);
  @include animation-fill-mode(both);
  @include animation-name(fadeIn);
}

/*Header Top Dropdowns*/
.header-top-dropdown .dropdown-menu {
  z-index: 103;
  padding: 20px;
  min-width: 280px;
  margin-top: 6px;
  border: 1px solid #D0D0D0;
  border-top: none;
  background: #f2f2f2;
  font-weight: 400;
  // RTL support.
  // The following mixin depends on the $text-direction variable in style.scss file OR in rtl_style.scss file.
  // RTL related mixins are located in mixins/_rtl.scss file.
  @include border-radius(0 0 5px 5px);
  // RTL support
  // $text-direction variable is setted to "ltr" inside style.scss file and to "rtl" inside rtl_style.scss file.
  @if $text-direction == rtl {
    right: auto;
    left: 0;
  }
}
.header-top-dropdown .login-form ul {
  // RTL support.
  // The following mixin depends on the $text-direction variable in style.scss file OR in rtl_style.scss file.
  // RTL related mixins are located in mixins/_rtl.scss file.
  @include padding-left(0);
  list-style-position: inside;
  margin: 15px 0 10px;
}
.header-top-dropdown .login-form .social-links {
  // RTL support.
  // The following mixin depends on the $text-direction variable in style.scss file OR in rtl_style.scss file.
  // RTL related mixins are located in mixins/_rtl.scss file.
  @include padding-left(20px);
  margin: 0;
}
.header-top-dropdown .login-form label {
  font-weight: 400;
}
.dark.header-top .dropdown-menu {
  border-top: none;
}
.colored.header-top .dropdown-menu {
  border-top: none;
}
.search-box .form-group {
  margin-bottom: 0;
}

/*Button Groups*/
.header-top .btn-group {
  margin: 3px 0 2px;
}
.header-top .btn-group .btn {
  margin: 0;
  text-decoration: none;
}

/* Extra small devices (phones, less than 575px) */
@media (max-width: 575px) {
  .header-top-dropdown .dropdown-menu {
    margin-top: 5px;
    width: 100%;
  }
}

/*Dark Dropdowns
---------------------------------- */
.dark.btn-group {
  .dropdown-menu {
    background-color: $gray-dark;
    border: 1px solid #424242;
    border-bottom: none;
  }
  .dropdown-menu .dropdown-divider {
    background-color: #505050;
  }
  .dropdown-menu .dropdown-item,
  .mega-menu .menu .dropdown-item {
    color: #cccccc;
    border-bottom: 1px solid #424242;
    text-shadow: 1px 1px rgba($black, 0.7);
  }
  .dropdown-menu .dropdown-item:hover,
  .dropdown-menu .dropdown-item:focus,
  .nav .show .dropdown-item,
  .nav .show .dropdown-item:hover,
  .nav .show .dropdown-item:focus,
  .dropdown-menu .active.dropdown-item,
  .dropdown-menu .active.dropdown-item:hover,
  .dropdown-menu .active.dropdown-item:focus {
    background-color: rgba($black, 0.2);
    color: $white;
    border-color: #424242;
  }
  .dropdown .dropdown .dropdown-item:before {
    color: #cccccc;
    top: 13px;
  }
  .dropdown .dropdown.show .dropdown-item:before {
    color: $white;
  }
}

/* Login/Register Forms
---------------------------------- */
.form-block {
  @include border-radius(10px);
  width: 630px;
}
.form-block .social-links {
  display: inline-block;
  // RTL support.
  // The following mixin depends on the $text-direction variable in style.scss file OR in rtl_style.scss file.
  // RTL related mixins are located in mixins/_rtl.scss file.
  @include margin(0, 0, 0, 5px);
}
.form-block ul {
  // RTL support.
  // The following mixin depends on the $text-direction variable in style.scss file OR in rtl_style.scss file.
  // RTL related mixins are located in mixins/_rtl.scss file.
  @include padding-left(0);
  list-style-position: inside;
}
.light-gray-bg.form-block h2,
.light-gray-bg.form-block a,
.light-gray-bg.form-block a:hover,
.light-gray-bg.form-block {
  color: $gray;
}

/* Small devices (tablets, phones less than 767px) */
@media (max-width: 767px) {
  .form-block {
    width: 100%;
  }
}

/* Latest tweets block
---------------------------------- */
ul.tweets {
  list-style: none;
  padding: 0;
  li {
    margin-bottom: 20px;
    position: relative;
  // RTL support.
  // The following mixin depends on the $text-direction variable in style.scss file OR in rtl_style.scss file.
  // RTL related mixins are located in mixins/_rtl.scss file.
    @include padding-left(35px);
  }
  li i {
    position: absolute;
    top: 4px;
    // RTL support.
    // The following mixin depends on the $text-direction variable in style.scss file OR in rtl_style.scss file.
    // RTL related mixins are located in mixins/_rtl.scss file.
    @include left(0);
    width: 25px;
    height: 25px;
    text-align: center;
    color: $gray;
  }
  li p {
    margin-bottom: 5px;
  }
  li span {
    font-size: 12px;
    color: $gray;
  }
}

/* Media
---------------------------------- */
.media {
  margin-top: 0;
  margin-bottom: 25px;
}
.media img {
  max-width: none;
}
.footer-content .media img,
.block .media img {
  width: 60px;
}
.media-heading {
  margin-bottom: 0;
}
h6.media-heading {
  margin-bottom: 5px;
}
.media:hover .icon {
  background-color: transparent;
}
.dark-bg .media:hover .icon {
  border-color: $gray-lighter;
  color: $gray-lighter;
}
.media:hover .icon:after {
  content: "";
  border-color: transparent;
}

/* Testimonials
---------------------------------- */
.testimonial {
  padding: 20px 0;
}
.testimonial blockquote {
  margin: 0;
}
.testimonial .separator {
  margin: 15px auto 5px;
}
.testimonial-image {
  width: 90px;
  margin: 0 auto;
}
.testimonial-info-1 {
  font-size: 12px;
}
.testimonial-info-2 {
  font-size: 12px;
  font-weight: 400;
}
.testimonial h3 {
  margin-bottom: 0;
  margin-top: 15px;
}
.testimonial p {
  margin-bottom: 15px;
}

/* Clients
---------------------------------- */
.clients-container {
  text-align: center;
}
.clients {
  margin: 10px auto;
  .client-image {
    text-align: center;
    width: 80px;
    height: 80px;
    margin: 5px;
    display: inline-block;
  }
  img {
    margin: 0px auto;
  }
}
.slick-carousel.clients .slick-slide img {
  width: auto;
}

/* Full Width Content
---------------------------------- */
.full-width-section {
  position: relative;
}
.full-text-container {
  position: absolute;
  top: 30%;
  padding: 20px 40px;
  z-index: 2;
}
.full-image-overlay {
  display: none;
  position: absolute;
  width: 100%;
  top: 50%;
  padding: 0 10%;
  margin-top: -133px;
  color: $white;
  z-index: 1;
  @include transition (all 0.3s ease-in-out);
  @include transition-delay(0s);
}
.full-image-overlay h3 {
  color: $white;
}
.to-right-block {
  margin-left: auto;
  display: block;
}

/* Large devices (Large desktops 1200px and up) */
@media (min-width: 1200px) {
  .full-width-section {
    position: relative;
    display: table;
    table-layout: fixed;
    width: 100%;
  }
  .full-width-section.no-image {
    min-height: 400px;
  }
  .full-image-container {
    width: 50%;
    display: table-cell;
    position: relative;
    overflow: hidden;
  }
  .full-image-overlay {
    display: block;
    opacity: 0;
    filter: alpha(opacity=0);
  }
  .full-image-container:before {
    content: '';
    position: absolute;
    top: 5%;
    right: 0%;
    width: 50%;
    height: 90%;
    background-color: rgba(55, 55, 55, 0.6);
    @include opacity(0);
    @include transition (all 0.3s ease-in-out);
    @include transition-delay(0.3s);
  }
  .full-text-container + .full-image-container:before {
    right: auto;
    left: 0;
  }
  .full-width-section:hover .full-image-container:before,
  .full-width-section .full-image-container.hovered:before {
    width: 90%;
    right: 5%;
    @include opacity(1);
    @include transition-delay(0s);
  }
  .full-width-section:hover .full-text-container + .full-image-container:before,
  .full-width-section .full-text-container + .full-image-container.hovered:before {
    right: auto;
    left: 5%;
  }
  .full-width-section.hover-disabled:hover .full-image-container:before,
  .full-width-section.hover-disabled .full-image-container.hovered:before {
    @include opacity(0);
  }
  .full-width-section:hover .full-image-overlay,
  .full-width-section .hovered .full-image-overlay {
    @include opacity(1);
    @include transition-delay(0.3s);
  }
  .full-text-container {
    display: table-cell;
    vertical-align: middle;
    width: 50%;
    padding: 0 50px;
    position: static;
  }
  .full-text-container * {
    max-width: 700px;
  }
  .full-text-container.left {
    text-align: right;
  }
  .full-text-container.left * {
    margin-left: auto;
  }
  .full-text-container p {
    margin-bottom: 10px;
  }
}

/* Large and Medium devices (desktops, tablets less than 1199px) */
@media (max-width: 1199px) {
  .no-image .full-text-container {
    position: static;
    display: block;
  }
  .full-width-section:not(.no-image) .full-text-container.dark-bg {
    background-color: rgba($black, 0.8);
    width: 100%;
  }
  .full-width-section:not(.no-image) .full-text-container.light-gray-bg {
    background-color: rgba($white, 0.8);
    color: $black;
    border-top: none;
    border-bottom: none;
    width: 100%;
  }
  .full-width-section:not(.no-image) .full-text-container.white-bg {
    background-color: rgba($white, 0.8);
    color: $black;
    border-top: none;
    border-bottom: none;
    width: 100%;
  }
  .full-width-section:not(.no-image) .full-text-container.default-bg {
    width: 100%;
  }
  .full-width-section .to-right-block,
  .full-width-section img {
    margin: 0 auto;
  }
}

/* Medium devices (desktops, tablets less than 991px) */
@media (max-width: 991px) {
  .full-width-section:not(.no-image) .full-text-container {
    top: 0;
    height: 100%;
    padding: 0 20px;
  }
  .full-text-container h1 {
    font-size: 28px;
  }
}

/* Extra small devices (phones, less than 575px) */
@media (max-width: 575px) {
  .full-text-container {
    position: static;
    padding: 20px!important;
  }
}

/* Call to action
---------------------------------- */
.call-to-action {
  h1,
  a {
    margin: 0;
  }
  p {
    margin-bottom: 10px;
  }
}

/* Small devices (tablets, phones less than 767px) */
@media (max-width: 767px) {
  .call-to-action .text-right {
    text-align: left;
  }
}

/* Counters
---------------------------------- */
.counter {
  display: block;
  font-size: 32px;
  line-height: 1;
}

/* Pricing tables
---------------------------------- */
.pricing-tables {
  margin: 60px 0 0;
}
.plan {
  text-align: center;
  margin-bottom: 40px !important;
  &.round-corners .header {
      // RTL support.
      // The following mixin depends on the $text-direction variable in style.scss file OR in rtl_style.scss file.
      // RTL related mixins are located in mixins/_rtl.scss file.
      @include border-radius(25px 25px 0 0);
  }
  &.round-corners ul li:last-child,
  &.round-corners ul {
    // RTL support.
    // The following mixin depends on the $text-direction variable in style.scss file OR in rtl_style.scss file.
    // RTL related mixins are located in mixins/_rtl.scss file.
    @include border-radius(0 0 25px 25px);
  }
  .header {
    padding: 0;
    margin: 0 -1px;
    border: none;
  }
  .header h3 {
    font-size: 36px;
    margin: 0;
    line-height: 36px;
    font-weight: 300;
    padding: 15px 0 10px;
    border: 1px solid rgba($black, 0.05);
    border-top: none;
  }
  .header .price {
    font-size: 28px;
    padding: 8px 0 10px;
    font-weight: 300;
    background-color: rgba($white, 0.1);
    border: 1px solid rgba($black, 0.1);
    border-top: 1px solid rgba($white, 0.15);
    line-height: 1;
    color: $white;
  }
  ul {
    margin: 0;
    padding: 0;
    font-size: 14px;
    list-style: none;
  }
  ul li {
    padding: 12px 0px;
    font-size: 13px;
    border-bottom: 1px solid #f3f3f3;
    border-top: 1px solid $white;
    color: $gray-dark;
  }
  ul.text-large li {
    font-size: 16px;
  }
  &.best-value ul li {
    padding: 15px 0;
  }
  ul li a.pt-popover {
    color: $black;
    border-bottom: 1px dashed $black;
    position: relative;
  }
  ul li a.pt-popover:hover {
    text-decoration: none;
  }
  ul li a.pt-popover:after {
    position: absolute;
    top: 0px;
    left: -15px;
    font-family: "FontAwesome";
    content: "\f059";
    color: #ddd;
    font-size: 12px;
  }
}
.popover {
  width: 250px;
  font-size: 13px;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.popover-title {
  color: $white;
  text-shadow: none;
  font-size: 13px;
  border: none;
}
.popover-content {
  font-size: 12px;
}
.circle-head .plan .header {
  padding: 0;
  border: none;
  width: 160px;
  height: 160px;
  @include border-radius(100%);
  margin: 20px auto;
  padding: 10px 0;
}
.circle-head .plan .header h3 {
  font-size: 26px;
  border: none;
  border-bottom: 1px solid rgba($black, 0.05);
  padding-top: 27px;
}
.circle-head .plan .header .price {
  height: 80px;
  border: none;
  padding-top: 15px;
  font-size: 22px;
  border-top: 1px solid rgba($white, 0.15);
}
.stripped .plan ul {
  @include box-shadow(0px -15px 32px 0px #cacaca inset);
  background: #fdfdfd;
}
.stripped .plan ul li {
  border: 1px solid #ececec;
  border-top: none;
  color: #666;
}
.stripped .plan ul li:nth-child(even) {
  @include box-shadow(0px 0px 40px #e4e4e4 inset);
  background: $white;
  border-right: 1px solid #e2e2e2;
  border-bottom: 1px solid #e2e2e2;
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .best-value.plan {
    top: -30px;
    position: relative;
    z-index: 2;
  }
}

/* Google maps
---------------------------------- */
#map-canvas {
  height: 450px;
}
#map-canvas.small {
  height: 347px;
}
#collapseMap:not(.show) {
  overflow: hidden;
  height: 0;
  @include transition (height 0.3s ease-in-out);
}
#collapseMap.in {
  height: 450px;
}
.footer-content #map-canvas {
  height: 300px;
}
#map-canvas img {
  max-width: inherit;
}

/* Tags cloud block
---------------------------------- */
.tags-cloud {
  font-size: 0;
}
.tag {
  display: inline-block;
  // RTL support.
  // The following mixin depends on the $text-direction variable in style.scss file OR in rtl_style.scss file.
  // RTL related mixins are located in mixins/_rtl.scss file.
  @include margin(0, 3px, 3px, 0);
  a {
    padding: 3px 10px;
    @include border-radius(4px);
    color: $white;
    display: block;
    font-size: 11px;
  }
  a:hover {
    background-color: $white;
    text-decoration: none;
  }
}
.dark .tag a {
  color: $gray;
  background-color: transparent;
  border: 1px solid $gray-dark;
}
.dark .tag a:hover {
  color: $white;
  background-color: $gray;
  border-color: $gray;
  text-decoration: none;
}

/* Credit Carts Block
---------------------------------- */
.icons-block i {
  display: inline-block;
  font-size: 30px;
  color: $gray-light;
}

/* Isotope filtering
---------------------------------- */
.isotope-container {
  display: none;
}
.isotope-container-fitrows {
  display: none;
}

/* Small devices (tablets, phones less than 991px) */
@media (max-width: 991px) {
  .isotope-item,
  .masonry-grid-item {
    max-width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .col-md-6.masonry-grid-item,
  .col-md-6.isotope-item {
    max-width: 50%;
  }
}

/* Affix Menu
---------------------------------- */
.sidebar.affix {
  position: static;
}

/* Show and affix the side nav when space allows it */
/* Medium devices (desktops, tablets, 992px and up) */
@media (min-width: 992px) {
  .col-md-4 .sidebar.affix,
  .col-md-4 .sidebar.affix-bottom {
    width: 293px;
  }
  .col-md-3 .sidebar.affix,
  .col-md-3 .sidebar.affix-bottom {
    width: 213px;
  }
  .sidebar.affix {
    position: fixed;
    top: 20px;
  }
  .fixed-header-on .sidebar.affix {
    top: 65px;
  }
  .sidebar.affix-bottom {
    position: absolute;
  }
  .sidebar.affix-bottom .affix-menu,
  .sidebar.affix .affix-menu {
    margin-top: 0;
    margin-bottom: 0;
  }
  .affix-menu {
    padding-top: 20px;
  }
  h3.title ~ .affix-menu {
    padding-top: 0px;
  }
}

/* Large devices (Large desktops 1200px and up) */
@media (min-width: 1200px) {
  .col-md-4 .sidebar.affix,
  .col-md-4 .sidebar.affix-bottom {
    width: 390px;
  }
  .col-md-3 .sidebar.affix,
  .col-md-3 .sidebar.affix-bottom {
    width: 263px;
  }
}

/* Scroll to top
---------------------------------- */
.scrollToTop {
  color: $white;
  position: fixed;
  bottom: 5px;
  right: 5px;
  width: 50px;
  height: 50px;
  text-align: center;
  cursor: pointer;
  background-color: rgba($black, 0.4);
  z-index: 1005;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  @include transition (all 0.2s ease-in-out);
  @include opacity(0);
}
.scrollToTop.fadeToTop {
  -webkit-animation-name: fadeToTop;
  animation-name: fadeToTop;
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes fadeToTop {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeToTop {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    -ms-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
}
.scrollToTop.fadeToBottom {
  -webkit-animation-name: fadeToBottom;
  animation-name: fadeToBottom;
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes fadeToBottom {
  0% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}
@keyframes fadeToBottom {
  0% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    -ms-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}
.scrollToTop i {
  line-height: 48px;
  font-size: 26px;
}
.scrollToTop:hover {
  background-color: rgba($black, 0.6);
}

/* Small devices (tablets, phones less than 767px) */
@media (max-width: 767px) {
  .scrollToTop {
    width: 40px;
    height: 40px;
    background-color: rgba($black, 0.4);
  }
  .scrollToTop i {
    line-height: 40px;
    font-size: 20px;
  }
}
