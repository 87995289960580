/* 3 Sections
----------------------------------------------------------------------------- */
/*Header Top*/
.header-top {
  font-size: 12px;
  color: $gray;
  font-weight: 300;
  &.dark,
  &.colored {
    color: $white;
  }
  &.colored a {
    color: $white;
    text-decoration: underline;
  }
  &.colored a:hover {
    text-decoration: none;
  }
}

/* Medium desktop devices and tablets */
@media (min-width: 992px) and (max-width: 1199px) {
  .header-top {
    font-size: 11px;
  }
}

/*Header*/
.header.dark {
  color: #cacaca;
}

/*Footer*/
.footer {
  font-size: 14px;
  line-height: 1.5;
  h2.title {
    font-size: 22px;
    text-transform: none;
    margin-bottom: 10px;
  }
}

/*Subfooter*/
.subfooter {
  font-size: 14px;
  line-height: 1.3;
  color: $gray-light;
  p {
    margin-bottom: 0;
  }
}
.subfooter.default-bg {
  color: $white;
}
