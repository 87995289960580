















































@media (min-width: 320px) {
  .img-container {
    text-align: center;
    background-size: cover;
    height: 150px;
  }

  .slideshow > div {
    height: 150px !important;
  }
}

@media (min-width: 576px) {
  .img-container {
    text-align: center;
    background-size: cover;
    height: 200px;
  }

  .slideshow > div {
    height: 200px !important;
  }
}

@media (min-width: 768px) {
  .img-container {
    text-align: center;
    background-size: cover;
    height: 350px;
  }

  .slideshow > div {
    height: 350px !important;
  }
}

@media (min-width: 992px) {
  .img-container {
    text-align: center;
    background-size: cover;
    height: 450px;
  }

  .slideshow > div {
    height: 450px !important;
  }
}

@media (min-width: 1200px) {
  .img-container {
    text-align: center;
    background-size: cover;
    height: 550px;
  }

  .slideshow > div {
    height: 550px !important;
  }
}

.slider-img-1 {
  background-image: url(../assets/slider-image1.jpg);
}
.slider-img-2 {
  background-image: url(../assets/slider-image2.jpg);
}
.slider-img-3 {
  background-image: url(../assets/slider-image3.jpg);
}
