// Core variables and mixins
@import 'variables';
@import "mixins";

/* 1 Typography
----------------------------------------------------------------------------- */
/*Fonts
---------------------------------- */
h1,
h2,
h3,
h4,
h5,
h6,
.navbar .navbar-nav {
  font-family: $headings-font-family;
}

body {
  font-family: $font-family-roboto;
}
button,
input,
optgroup,
select,
textarea {
  font-family: $font-family-roboto;
}
.logo-font {
  font-family: $font-family-pacifico;
}

/*Typography
---------------------------------- */
html {
  height: 100%;
}
body {
  font-size: $font-size-base;
  line-height: $line-height-base;
  color: $gray;
  background-color: $body-bg;
  font-weight: 300;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: $gray-dark;
  line-height: $headings-line-height;
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: $gray-dark;
}
.text-white {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $white;
  }
  h1 a,
  h2 a,
  h3 a,
  h4 a,
  h5 a,
  h6 a {
    color: $white;
    text-decoration: underline;
  }
}
.dark h1,
.dark h2,
.dark h3,
.dark h4,
.dark h5,
.dark h6 {
  color: $white;
}
.dark {
  h1 a,
  h2 a,
  h3 a,
  h4 a,
  h5 a,
  h6 a {
    color: $white;
  }
  .footer {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $gray-light;
    }
    h1 a,
    h2 a,
    h3 a,
    h4 a,
    h5 a,
    h6 a {
      color: $gray-light;
    }
  }
}
h1 {
  font-size: $font-size-h1;
  font-weight: 700;
  text-transform: uppercase;
}
h1.logo-font {
  text-transform: none;
  font-weight: 300;
  font-size: 50px;
}
h1.large {
  font-size: $font-size-h1-large;
  font-weight: 300;
  letter-spacing: 0.1em;
}
h2 {
  font-size: $font-size-h2;
  margin-bottom: 15px;
  text-transform: uppercase;
}
h2.logo-font {
  text-transform: none;
}
h3 {
  font-size: $font-size-h3;
}
h4 {
  font-size: $font-size-h4;
}
h5 {
  font-size: $font-size-h5;
  text-transform: uppercase;
  font-weight: 700;
}
h6 {
  font-size: $font-size-h6;
  font-weight: 700;
}

/* Small devices (tablets, 768px and up) */
@media screen and (min-width: 768px) {
  h1.extra-large {
    font-size: 130px;
    font-weight: 700;
  }
}

/* Small devices (tablets, phones less than 767px) */
@media screen and (max-width: 767px) {
  h1.extra-large {
    font-size: 60px;
    font-weight: 700;
  }
}
.page-title {
  margin-top: 0;
}

/* Medium devices (desktops, tablets, 992px and up) */
@media (min-width: 992px) {
  .title {
    margin-top: 0;
  }
}
.sidebar .title {
  margin-top: 3px;
}
p {
  margin-bottom: 15px;
}
p.large {
  font-size: $font-size-large;
  line-height: 1.4;
  margin-bottom: 30px;
}
a {
  @include transition(all 0.2s ease-in-out);
  &:focus,
  &:active {
    outline: none;
  }
}
.link-dark {
  color: $gray-dark;
  text-decoration: none!important;
  &:hover {
    color: $gray-dark;
    text-decoration: underline!important;
  }
}
.dark .footer .link-dark {
  color: $gray;
  text-decoration: none!important;
  &:hover {
    color: $gray;
    text-decoration: underline!important;
  }
}
.link-light {
  color: $white;
  text-decoration: none!important;
  &:hover {
    color: $white;
    text-decoration: underline!important;
  }
}
blockquote {
  border-left: none;
  display: inline-block;
  margin: 20px auto 20px;
  font-size: 16px;
  position: relative;
  padding: 10px 25px;
  &:after {
    content: "“";
    width: 25px;
    height: 25px;
    line-height: 36px;
    font-size: 36px;
    font-family: $font-family-pt-serif;
    position: absolute;
    top: 12px;
    left: 0px;
    color: $gray-lighter;
  }
}
.blockquote:before {
  font-family: $font-family-pt-serif;
  content: "“";
  font-size: 40px;
}
blockquote.inline {
  padding: 0;
  p {
    width: 60%;
    display: inline-block;
    margin: 0;
  }
  footer {
    width: 37%;
    display: inline-block;
    padding-left: 5px;
  }
  &:after {
    top: 0;
  }
}
pre {
  padding: 20px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  background-color: #fafafa;
  border: 1px solid #f1f1f1;
}
.text-muted {
  color: $gray-light !important;
}
.text-white {
  color: $white!important;
}
.well {
  @include border-radius(0px);
  background-color: #f3f3f3;
  @include box-shadow(inset 0 1px 2px rgba(0, 0, 0, 0.05));
  min-height: 20px;
  padding: 20px;
  margin-bottom: 20px;
  border: 1px solid #e3e3e3;
}
hr {
  border-color: #eee;
}
.dark hr {
  border-color: $gray-dark;
}
